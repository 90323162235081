import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container, Box } from '@mui/material';
import Header from './components/Header';
import Footer from './components/Footer';
import Sidebar from './components/Sidebar';
import Dashboard from './components/Dashboard';
import Courses from './components/Courses';
import FinanceCalculator from './components/FinanceCalculator';
import Advice from './components/Advice';

const App: React.FC = () => {
  const [calculatorData, setCalculatorData] = useState<{ income: number, expenses: number } | null>(null);

  const handleCalculate = (income: number, expenses: number) => {
    setCalculatorData({ income, expenses });
  };

  return (
    <Router>
      <Header />
      <Box display="flex">
        <Sidebar />
        <Container component="main" style={{ flexGrow: 1, padding: '2rem' }}>
          <Routes>
            <Route path="/" element={<Dashboard calculatorData={calculatorData} />} />
            <Route path="/courses" element={<Courses />} />
            <Route path="/calculator" element={<FinanceCalculator onCalculate={handleCalculate} />} />
            <Route path="/advice" element={<Advice />} />
          </Routes>
        </Container>
      </Box>
      <Footer />
    </Router>
  );
};

export default App;
