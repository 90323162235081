import React, { useState, useEffect } from 'react';
import { Typography, Grid, Paper } from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import IncomeExpenseForm from './IncomeExpenseForm';

const Dashboard: React.FC<{ calculatorData: { income: number, expenses: number } | null }> = ({ calculatorData }) => {
  const [items, setItems] = useState<{ type: string, category: string, amount: number, description: string }[]>([]);

  const handleAddItem = (item: { type: string, category: string, amount: number, description: string }) => {
    setItems((prevItems) => [...prevItems, item]);
  };

  const calculateTotal = (type: string) => {
    return items
      .filter((item) => item.type === type)
      .reduce((total, item) => total + item.amount, 0);
  };

  const incomeTotal = calculateTotal('income');
  const expenseTotal = calculateTotal('expense');
  const balance = incomeTotal - expenseTotal;

  const calculatorChartData = calculatorData
    ? {
        labels: ['Ingresos', 'Gastos'],
        datasets: [
          {
            data: [calculatorData.income, calculatorData.expenses],
            backgroundColor: ['rgba(54, 162, 235, 0.2)', 'rgba(255, 99, 132, 0.2)'],
            borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)'],
            borderWidth: 1,
          },
        ],
      }
    : null;

  return (
    <Grid container spacing={3} justifyContent="center" style={{ marginTop: '2rem' }}>
      <Grid item xs={12} md={8}>
        <Paper style={{ padding: '2rem' }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Tablero
          </Typography>
          <IncomeExpenseForm onAdd={handleAddItem} />
          <Typography variant="h6" component="p" style={{ marginTop: '2rem' }}>
            Ingresos Totales: €{incomeTotal.toFixed(2)}
          </Typography>
          <Typography variant="h6" component="p" style={{ marginTop: '1rem' }}>
            Gastos Totales: €{expenseTotal.toFixed(2)}
          </Typography>
          <Typography variant="h6" component="p" style={{ marginTop: '1rem', color: balance >= 0 ? 'green' : 'red' }}>
            Balance: €{balance.toFixed(2)}
          </Typography>
          {calculatorChartData && (
            <div style={{ height: '300px', marginTop: '2rem' }}>
              <Doughnut data={calculatorChartData} options={{ responsive: true, maintainAspectRatio: false }} />
            </div>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
