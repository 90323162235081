import React from 'react';
import { Typography, Grid, Paper } from '@mui/material';

const courses = [
  {
    title: 'Introducción a la Educación Financiera',
    description: 'Este curso te proporcionará una base sólida en los principios básicos de la educación financiera.',
  },
  {
    title: 'Planificación Financiera Personal',
    description: 'Aprende a planificar y gestionar tus finanzas personales de manera efectiva.',
  },
];

const Courses: React.FC = () => {
  return (
    <Grid container spacing={3} justifyContent="center" style={{ marginTop: '2rem' }}>
      {courses.map((course, index) => (
        <Grid item xs={12} md={8} key={index}>
          <Paper style={{ padding: '2rem' }}>
            <Typography variant="h5" component="h2" gutterBottom>
              {course.title}
            </Typography>
            <Typography variant="body1">{course.description}</Typography>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default Courses;
