import React, { useState } from 'react';
import { Typography, Grid, Paper, TextField, Button } from '@mui/material';

const FinanceCalculator: React.FC<{ onCalculate: (income: number, expenses: number) => void }> = ({ onCalculate }) => {
  const [income, setIncome] = useState<number>(0);
  const [expenses, setExpenses] = useState<number>(0);

  const handleCalculate = () => {
    onCalculate(income, expenses);
  };

  return (
    <Grid container spacing={3} justifyContent="center" style={{ marginTop: '2rem' }}>
      <Grid item xs={12} md={6}>
        <Paper style={{ padding: '2rem', textAlign: 'center' }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Calculadora Financiera
          </Typography>
          <TextField
            label="Ingresos"
            type="number"
            value={income}
            onChange={(e) => setIncome(Number(e.target.value))}
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <TextField
            label="Gastos"
            type="number"
            value={expenses}
            onChange={(e) => setExpenses(Number(e.target.value))}
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <Button variant="contained" color="primary" onClick={handleCalculate} style={{ marginTop: '1rem' }}>
            Calcular
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default FinanceCalculator;
