import React, { useState } from 'react';
import { Typography, Grid, Paper, TextField, Button, MenuItem } from '@mui/material';

const categories = ["Compra", "Hipoteca", "Alquiler", "Luz", "Agua", "Internet", "Gasolina","Ingresos"];

const IncomeExpenseForm: React.FC<{ onAdd: (item: { type: string, category: string, amount: number, description: string }) => void }> = ({ onAdd }) => {
  const [type, setType] = useState<string>('income');
  const [category, setCategory] = useState<string>('');
  const [amount, setAmount] = useState<number>(0);
  const [description, setDescription] = useState<string>('');

  const handleAdd = () => {
    if (amount > 0 && description.trim() !== '') {
      onAdd({ type, category, amount, description });
      setType('income');
      setCategory('');
      setAmount(0);
      setDescription('');
    }
  };

  return (
    <Paper style={{ padding: '1rem', marginBottom: '1rem' }}>
      <Typography variant="h6" gutterBottom>
        Agregar Ingreso/Gasto
      </Typography>
      <TextField
        select
        label="Tipo"
        value={type}
        onChange={(e) => setType(e.target.value)}
        fullWidth
        margin="normal"
        variant="outlined"
      >
        <MenuItem value="income">Ingreso</MenuItem>
        <MenuItem value="expense">Gasto</MenuItem>
      </TextField>
      <TextField
        label="Categoría"
        value={category}
        onChange={(e) => setCategory(e.target.value)}
        fullWidth
        margin="normal"
        variant="outlined"
        select
      >
        {categories.map((cat) => (
          <MenuItem key={cat} value={cat}>
            {cat}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Cantidad"
        type="number"
        value={amount}
        onChange={(e) => setAmount(Number(e.target.value))}
        fullWidth
        margin="normal"
        variant="outlined"
      />
      <TextField
        label="Descripción"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        fullWidth
        margin="normal"
        variant="outlined"
      />
      <Button variant="contained" color="primary" onClick={handleAdd} style={{ marginTop: '1rem' }}>
        Agregar
      </Button>
    </Paper>
  );
};

export default IncomeExpenseForm;
