import React from 'react';
import { Typography, Grid, Paper } from '@mui/material';

const advices = [
  'Establece un presupuesto mensual y cúmplelo.',
  'Ahorra al menos el 20% de tus ingresos.',
  'Evita las deudas de tarjetas de crédito con altos intereses.',
  'Invierte en educación para mejorar tus habilidades y aumentar tu ingreso.',
];

const Advice: React.FC = () => {
  return (
    <Grid container spacing={3} justifyContent="center" style={{ marginTop: '2rem' }}>
      {advices.map((advice, index) => (
        <Grid item xs={12} md={8} key={index}>
          <Paper style={{ padding: '2rem' }}>
            <Typography variant="body1">{advice}</Typography>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default Advice;
