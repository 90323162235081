import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import 'chart.js/auto';

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
