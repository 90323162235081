import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';

const Sidebar: React.FC = () => {
  return (
    <List component="nav" style={{ width: '200px', padding: '1rem', backgroundColor: '#f5f5f5', height: '100vh' }}>
      <ListItem button component={Link} to="/">
        <ListItemText primary="Tablero" />
      </ListItem>
      <ListItem button component={Link} to="/courses">
        <ListItemText primary="Cursos" />
      </ListItem>
      <ListItem button component={Link} to="/calculator">
        <ListItemText primary="Calculadora Financiera" />
      </ListItem>
      <ListItem button component={Link} to="/advice">
        <ListItemText primary="Consejos Financieros" />
      </ListItem>
    </List>
  );
};

export default Sidebar;
